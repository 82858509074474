import React, { useEffect, useRef, useState } from "react";
import api from 'Api';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Text from "Components/shared/Text";
import { DYNAMIC_PRICE_POLLING_INTERVAL } from 'Helpers/assets';
import { useFormatNumber } from "Utils/formatNumber";

const GOLD_CURRENCIES = ["USD", "EUR", "CHF"];

const GoldPrices = () => {
  const [prices, setPrices] = useState([]);
  const [error, setError] = useState(null);
  const pollingInterval = useRef(null);
  const formatNumber = useFormatNumber();

  const stopPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const prices = await api.CustomerFeatures.getFineGoldPrices();
        if (!prices.length) {
          setError(i18nextTranslate(i18nextKeys.errorDefaultMessage));
        }
        const filtered = prices.filter((price) => GOLD_CURRENCIES.includes(price.currency));
        filtered.sort((a, b) => GOLD_CURRENCIES.indexOf(a.currency) - GOLD_CURRENCIES.indexOf(b.currency));
        setPrices(filtered);
      } catch (error) {
        setError(i18nextTranslate(i18nextKeys.errorDefaultMessage));
      }
    }
    if (!pollingInterval.current) {
      fetchPrices();
      pollingInterval.current = setInterval(fetchPrices, DYNAMIC_PRICE_POLLING_INTERVAL);
    }
    return stopPolling;
  }, []);

  return (
    <div
      className="py-12 px-8 m-16 rounded border"
      style={{
        backgroundColor: "#0c0c0c",
        borderColor: "#C9A73F",
        boxShadow: "0px 0px 3px #C9A73F, 0px 0px 3px #C9A73F"
      }}
    >
      <div>
        <Text
          textStyle="h1"
          style={{ color: "#C9A73F", fontWeight: "700" }}
          className="mb-6"
          center
        >
          {i18nextTranslate(i18nextKeys.customGoldPriceTitle)}
        </Text>
      </div>
      {!error && !prices.length && <LoadingSpinner size="h-32 w-32 mx-auto" />}
      {error && !prices.length && (
        <Text
          color="color-red"
          textStyle="p2"
          center
        >
          {error}
        </Text>
      )}
      {prices.length ? (
        <table className="w-full">
          <colgroup>
            <col className="w-2/4 border-r" style={{ borderColor: "#C9A73F" }} />
            <col className="w-2/4" />
          </colgroup>
          <thead>
            <tr>
              <th className="text-right pr-6">
                <Text
                  textStyle="h2"
                  style={{ color: "#C9A73F", fontWeight: "500" }}
                  className="mb-6"
                >
                  {i18nextTranslate(i18nextKeys.customGoldPriceBuy)}
                </Text>
              </th>
              <th className="text-left pl-6">
                <Text
                  textStyle="h2"
                  style={{ color: "#C9A73F", fontWeight: "500" }}
                  className="mb-6"
                >
                  {i18nextTranslate(i18nextKeys.customGoldPriceSell)}
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {prices.map((price, index) => (
              <tr key={`${price.currency}-${index}`}>
                <td className="text-right pr-6">
                  <Text style={{ color: "#C9A73F" }}>
                    {`${price.currency}: ${formatNumber(price.buy, 2, 2)}`}
                  </Text>
                </td>
                <td className="text-left pl-6">
                  <Text style={{ color: "#C9A73F" }}>
                    {`${formatNumber(price.sell, 2, 2)}`}
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default GoldPrices;
